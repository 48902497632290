<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "TeamworkIndex",
    created() {
        document.title = "Teamwork";
        this.$store.dispatch("definition/getDefinitionSets", { project_id: this.$route.params.id });
    }
};
</script>
